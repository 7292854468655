import React, { useState } from 'react';

export const Test = () => {
  // 테스트 컴포넌트
  // 멀티태그 입력
  // 토스트 메시지 등등
  // 테스트 항목은 이쪽에 먼저 구성 후 커스텀해서 사용한다.
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadImageUrl, setUploadImageUrl] = useState([]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleBlur = () => {
    if (inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleUploadImage = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const imgUrl = files.map((file) => URL.createObjectURL(file));
      setUploadImageUrl([...uploadImageUrl, imgUrl]);
      setUploadImage([...uploadImage, ...files]);
    }
  };

  return (
    <div className="h-screen">
      <h2>멀티입력 테스트</h2>
      <div className="tag-input-container flex flex-wrap border p-1">
        <div className="tag-list flex items-center rounded-full">
          {tags.map((tag, index) => (
            <div key={index} className="tag rounded-lg border border-blue-200 px-2 py-1 m-1">
              {tag}
              <button onClick={() => removeTag(index)}>x</button>
            </div>
          ))}
        </div>
        <input
          className="flex-1 p-1 focus:outline-none"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          placeholder="Enter a value and press Enter"
        />
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-300" />
      <h2>토스트 메시지 형식</h2>

      <div
        id="toast-default"
        class="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
        role="alert">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
          <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.147 15.085a7.159 7.159 0 0 1-6.189 3.307A6.713 6.713 0 0 1 3.1 15.444c-2.679-4.513.287-8.737.888-9.548A4.373 4.373 0 0 0 5 1.608c1.287.953 6.445 3.218 5.537 10.5 1.5-1.122 2.706-3.01 2.853-6.14 1.433 1.049 3.993 5.395 1.757 9.117Z"
            />
          </svg>
          <span class="sr-only">Fire icon</span>
        </div>
        <div class="ms-3 text-sm font-normal">Set yourself free.</div>
        <button
          type="button"
          class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-default"
          aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 " />
      <h2>업로드이미지 미리보기</h2>
      <div className="gallary">
        <div className="w-10 h-10 flex flex-row">
          {uploadImageUrl.map((img) => (
            <img src={img} alt="thumbnail"></img>
          ))}
        </div>
        <label class="block mb-2 text-sm font-medium" for="file_input">
          Upload file
        </label>
        <input
          class="block w-full text-sm border border-black rounded-lg cursor-pointer  focus:outline-none"
          aria-describedby="file_input_help"
          id="file_input"
          type="file"
          onChange={handleUploadImage}></input>
        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
          SVG, PNG, JPG or GIF (MAX. 800x400px).
        </p>
      </div>
    </div>
  );
};
