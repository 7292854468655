import React, { useCallback, useEffect, useRef, useState } from 'react';
import API from '../api/Instance';
import { CardSlider } from '../common/CardSlider';

export const Main = () => {
  const [popular, setPopular] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    type: 'TITLE',
    keyword: '',
  });
  const [page, setPage] = useState(0);
  const [last, setLast] = useState(false);
  const observer = useRef();

  useEffect(() => {
    API.get('/popular').then((res) => {
      setPopular(res.data);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 무한스크롤
  const handleLastObserver = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entires) => {
        if (entires[0].isIntersecting && !last) {
          setPage((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [last]
  );

  const submitSearch = () => {
    window.location.href = '/search?type=' + search.type + '&keyword=' + search.keyword;
  };

  const fetchData = async () => {
    API.get('/list?page=' + page).then((res) => {
      console.log(res.data);
      setData((prev) => [...prev, ...res.data.content]);
      setLast(data.last);
    });
  };

  return (
    <div id="container" className="flex flex-col p-5 h-screen">
      <div className="search-bar">
        <form class="max-w-md mx-auto">
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              class="block w-full p-4 ps-10 text-sm rounded-lg border border-blue-500"
              placeholder="서비스팟"
              name="keyword"
              onChange={handleSearchInput}
              required
            />
            <button
              type="button"
              class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={submitSearch}>
              Search
            </button>
          </div>
        </form>
      </div>
      {/* 메뉴 */}
      <div className="flex space-x-8 p-6 items-center justify-center">
        <a href="#" className="flex flex-col items-center text-gray-700 hover:text-blue-600">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M22 11.414v12.586h-20v-12.586l-1.293 1.293-.707-.707 12-12 12 12-.707.707-1.293-1.293zm-6 11.586h5v-12.586l-9-9-9 9v12.586h5v-9h8v9zm-1-7.889h-6v7.778h6v-7.778z" />
          </svg>
          <span>Home</span>
        </a>
        <a href="#" className="flex flex-col items-center text-gray-700 hover:text-blue-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M13 16h-2v-6h2v6zm-1-10.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm0-2.75c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006z" />
          </svg>
          <span>About</span>
        </a>
        <a href="#" className="flex flex-col items-center text-gray-700 hover:text-blue-600">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m18.891 19.498h-13.782l-1.52-9.501h16.823zm-14.306-12.506h14.868l-.227 1.506h-14.415zm.993-2.494h12.882l-.13.983h-12.623zm16.421 4.998c0-.558-.456-.998-1.001-.998h-.253c.309-2.064.289-1.911.289-2.009 0-.58-.469-1.008-1-1.008h-.189c.193-1.461.187-1.399.187-1.482 0-.671-.575-1.001-1.001-1.001h-14.024c-.536 0-1.001.433-1.001 1 0 .083-.008.013.188 1.483h-.19c-.524 0-1.001.422-1.001 1.007 0 .101-.016-.027.29 2.01h-.291c-.569 0-1.001.464-1.001.999 0 .118-.105-.582 1.694 10.659.077.486.496.842.988.842h14.635c.492 0 .911-.356.988-.842 1.801-11.25 1.693-10.54 1.693-10.66z"
              fill-rule="nonzero"
            />
          </svg>
          <span>Services</span>
        </a>
        <a href="/login" className="flex flex-col items-center text-gray-700 hover:text-blue-600">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
          </svg>
          <span>로그인</span>
        </a>
      </div>
      <h2 class="ml-2 flex flex-row items-center">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181" />
        </svg>
        <span className="m-1">Popular</span>
      </h2>
      {/* 카드형 데이터는 10개 정도 보여주자 */}
      <div id="company-list" className="w-full block">
        <CardSlider data={popular} className="w-full"></CardSlider>
      </div>
      <hr class="h-px mt-8 mb-2 bg-gray-200 border-0 dark:bg-gray-300"></hr>
      <div className="list flex-1 overflow-auto">
        {/* 해당 리스트는 페이징 처리 */}
        <ul className="space-y-4">
          {data.map((dat, index) => (
            <li
              className="bg-gray-100 p-4 rounded-md shadow-md"
              onClick={() => {
                window.location.href = '/company?id=' + dat.id;
              }}
              ref={index === data.length - 1 ? handleLastObserver : null}>
              {dat.name} - {dat.title}
            </li>
          ))}
        </ul>
      </div>
      <div
        className="fixed rounded-full bg-cyan-200 w-12 h-12 bottom-10 right-8"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/resource/image/chat.png)`,
          backgroundSize: '32px 32px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}></div>
    </div>
  );
};
