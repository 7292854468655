import React, { useEffect, useState } from 'react';
import API from '../api/Instance';

export const WriteCategory = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState({
    title: '',
  });

  useEffect(() => {
    API.get('/category/list').then((res) => {
      console.log(res.data);
      setCategoryList(res.data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory(() => ({
      [name]: value,
    }));
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      API.post('/category', category, {
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => {
        if (res.status === 200) {
          setCategoryList([...categoryList, category]);
        }
      });
      setCategory({ title: '' });
    }
  };

  const clickDeleteButton = (e, id) => {
    console.log(id);
    API.delete('/category/delete?id=' + id).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  };

  return (
    <div>
      {/* 카테고리 추가*/}
      <div class="flex flex-col gap-4">
        <div className="xl:-ml-5 border-solid border-2 border-black-500 p-2">
          <label htmlFor="category-title">카테고리명</label>
          <input
            className="ml-2"
            id="cateogory-title"
            name="title"
            value={category.title}
            onChange={handleChange}
            onKeyDown={handlePressEnter}></input>
        </div>
      </div>
      {/* 카테고리 리스트 출력 */}
      <div className="flex flex-col gap-4 h-screen mt-4">
        {categoryList.map((data) => (
          <div className="flex flex-row w-[150px]">
            <p className="text-blue-600 font-semibold mr-2">{data.title}</p>
            <button
              className="text-[10px]"
              type="button"
              onClick={() => {
                clickDeleteButton(this, data.id);
              }}>
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
