import React from 'react';

export const Header = () => {
  return (
    <div
      className="h-24"
      onClick={() => {
        window.location.href = '/';
      }}>
      <img className="opacity-75" src="/resource/image/icon_tp.png" alt="icon" width="128" height="128"></img>
    </div>
  );
};
