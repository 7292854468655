import React, { useState } from 'react';
import API from '../api/Instance';

export const Login = () => {
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const handleInput = (e) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitLogin = (e) => {
    e.preventDefault();
    API.post('/member/login', user, {
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        window.location.href = '/';
      } else {
        alert('로그인에 실패하였습니다. 자세한 사항은 관리자에게 문의주십시오.');
      }
    });
  };

  const moveRegister = (e) => {
    e.preventDefault();
    window.location.href = '/register';
  };

  return (
    <figure className="h-screen flex bg-gray-100">
      <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
        <blockquote className="text-2xl font-medium text-center">
          <p className="text-lg font-semibold">서비스팟</p>
        </blockquote>

        <div className="text-primary m-6">
          <div className="flex items-center mt-3 justify-center">
            <h1 className="text-2xl font-medium text-primary mt-4 mb-2">로그인</h1>
          </div>
          <form>
            <input
              name="username"
              type="text"
              value={user.username}
              onChange={handleInput}
              placeholder="아이디"
              className={'w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'}
            />
            <input
              name="password"
              type="password"
              value={user.password}
              onChange={handleInput}
              placeholder="비밀번호"
              className={'w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'}
            />
            <div className="flex items-center mt-3 justify-center  w-full">
              <button
                className={
                  'bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black w-full'
                }
                value="Login"
                onClick={submitLogin}>
                로그인
              </button>
            </div>
          </form>
          <div className="flex items-center mt-3 justify-center">
            <button type="button" className={'justify-center text-blue-500 hover:underline'} onClick={moveRegister}>
              회원가입
            </button>
          </div>
        </div>
      </div>
    </figure>
  );
};
