import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import jsonp from 'jsonp';
import React, { useEffect, useState } from 'react';

export const Maps = ({ address }) => {
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const [map, setMap] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });

  const containerStyle = {
    width: '500px',
    height: '500px',
  };

  useEffect(() => {
    // 위경도 변환 APi 호출
    if (!address) return;
    const url =
      'https://api.vworld.kr/req/address?service=address&request=getcoord&version=2.0&crs=epsg:4326&address=' +
      address.address +
      '&refine=true&simple=false&format=json&type=road&key=' +
      process.env.REACT_APP_VWORLD_API_KEY;

    jsonp(url, null, (err, response) => {
      if (err) {
        console.error('호출 실패');
      } else {
        // 1. JSONP 응답에서 실제 JSON 데이터에 접근
        const { response: apiResponse } = response;

        // 2. 필요한 값 추출 (status, x, y 좌표 등)
        const status = apiResponse.status;
        const coordinates = apiResponse.result ? apiResponse.result.point : null;

        console.log(coordinates);

        setLocation({
          lat: parseFloat(coordinates.x),
          lng: parseFloat(coordinates.y),
        });
      }
    });
  }, [address]);

  const onLoad = React.useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(location);
      map.fitBounds(bounds);

      setMap(map);
    },
    [location]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={14} onLoad={onLoad} onUnmount={onUnmount}>
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};
