import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../api/Instance';

export const Search = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const type = query.get('type');
  const keyword = query.get('keyword');

  useEffect(() => {
    if (keyword != null && type != null) {
      API.get('/company/search?type=' + type + '&keyword=' + keyword).then((response) => {
        if (response.data != null) {
          setData(response.data);
        }
      });
    }
  }, [keyword, type]);

  return (
    <div>
      {data.map((card, index) => (
        <div key={index} className="p-2">
          <div
            className="card min-w-72 min-h-48 max-w-72 max-h-48 border-solid border-black border rounded-md m-2 bg-cover flex items-end"
            style={
              card.thumbNail.length > 0
                ? {
                    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/image?id=${card.thumbNail[0].id})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }
                : {}
            }
            onClick={() => {
              window.location.href = '/company?id=' + card.id;
            }}>
            <div className="flex flex-col text-teal-50 shadow-lg bg-black bg-opacity-60 w-full">
              <p className="font-semibold ml-2">{card.name}</p>
              <p className="ml-2">{card.job}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
