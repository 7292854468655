import React, { useEffect, useState } from 'react';
import API from '../api/Instance';
import { useNavigate } from 'react-router-dom';
import { Editor } from '../assets/Editor';
import DaumPostCode from 'react-daum-postcode';

export const WriteForm = () => {
  const [form, setForm] = useState({
    name: '',
    title: '',
    categories: [],
    job: '',
    skillStack: [],
    tagList: [],
    address: {
      sido: '',
      sigungu: '',
      address: '',
      deteil: '',
    },
    introduce: '',
    deadLine: new Date(),
    applyUrlList: [],
  }); // 회사 정보
  const [postCode, setPostCode] = useState('');
  const [categoryList, setCategoryList] = useState([]); //카테고리
  const [uploadImage, setUploadImage] = useState([]); //썸네일
  const [uploadImageUrl, setUploadImageUrl] = useState([]); //썸네일 미리보기
  const [urlList, setUrlList] = useState([{ value: '' }]); //입사지원 URL
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const themeObj = {
    bgColor: '#FFFFFF',
    pageBgColor: '#FFFFFF',
    postcodeTextColor: '#C05850',
    emphTextColor: '#222222',
  };

  const postCodeStyle = {
    width: '360px',
    height: '480px',
  };

  useEffect(() => {
    API.get('/category/list').then((res) => {
      if (res.status === 200) {
        setCategoryList(res.data);
      }
    });
  }, []);

  const addUrlInput = () => {
    setUrlList([...urlList, { value: '' }]);
  };

  const removeUrlInput = (index) => {
    if (urlList.length > 1) {
      // 해당 index를 제외한 나머지 요소들만 유지
      const values = urlList.filter((_, i) => i !== index);
      setUrlList(values);
    }
  };

  const handleUrlInput = (index, e) => {
    const values = [...urlList];
    values[index].value = e.target.value;
    setUrlList(values);
  };

  // FormData 설정
  // 회사 정보 값을 설정한다.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevDate) => ({
      ...prevDate,
      [name]: value,
    }));
  };

  // 썸네일 업로드 코드
  // 미리보기 URL 생성
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imgUrl = files.map((file) => URL.createObjectURL(file));
    setUploadImageUrl([...uploadImageUrl, imgUrl]);
    setUploadImage([...uploadImage, ...files]);
  };

  // 회사소개글 입력 이벤트
  // CKEditor에서 사용한다.
  const handleIntroduceInput = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data });
    setForm({
      ...form,
      introduce: data,
    });
  };

  // 카테고리 입력
  const handleCategoryInput = (event) => {
    setForm((prevForm) => ({
      ...prevForm,
      categories: [event.target.value],
    }));
  };

  // 폼 전송
  const formSubmit = (e) => {
    e.preventDefault();
    // URL 리스트를 배열로 변환
    const urlStringList = urlList.map((url) => url.value);

    // applyUrlList 업데이트
    setForm((prevForm) => ({
      ...prevForm,
      applyUrlList: urlStringList, // 특정 필드만 업데이트
    }));

    console.log(form);

    setIsSubmitting(true);
  };

  useEffect(() => {
    if (!isSubmitting) return;

    const formData = new FormData();

    // 이미지 파일 추가
    if (uploadImage !== null) {
      for (let i = 0; i < uploadImage.length; i++) {
        formData.append('uploadImage', uploadImage[i]);
      }
    }
    // JSON 형태로 변환하여 formData에 추가
    formData.append('form', new Blob([JSON.stringify(form)], { type: 'application/json' }));

    // API 호출
    API.post('/write', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          navigate('/');
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [isSubmitting, form]);

  const toggleHandler = () => {
    setIsOpened(true);
  };

  const handleClosePopup = (state) => {
    setIsOpened(false);
  };

  const handleAddressHandler = (data) => {
    console.log(data);
    setForm((prevForm) => ({
      ...prevForm,
      address: {
        sido: data.sido,
        sigungu: data.sigungu,
        address: data.address,
      },
    }));
    setPostCode(data.zonecode);
  };

  return (
    <div className="container mx-auto p-1">
      <form onSubmit={formSubmit}>
        <div className="md:flex md:items-center mb-6">
          <div className="gallary">
            <div className="w-10 h-10 flex flex-row">
              {uploadImageUrl.map((img) => (
                <img src={img} alt="thumbnail"></img>
              ))}
            </div>
            <label class="block mb-2 text-sm font-medium" for="file_input">
              Upload file
            </label>
            <input
              class="block w-full text-sm border border-black rounded-lg cursor-pointer  focus:outline-none"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              onChange={handleFileChange}></input>
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
              SVG, PNG, JPG or GIF (MAX. 800x400px).
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="name">
              이름
              <input name="name" value={form?.name} onChange={handleChange} />
            </label>
            <label htmlFor="title">
              제목
              <input name="title" value={form?.title} onChange={handleChange} />
            </label>
            <div className="select-layout">
              <label>카테고리</label>
              <select onChange={handleCategoryInput}>
                <option>선택</option>
                {categoryList.map((cate) => (
                  <option value={cate.title}>{cate.title}</option>
                ))}
              </select>
            </div>

            <label>
              직무
              <input name="job" value={form?.job} onChange={handleChange} />
            </label>
            <label>
              구분
              <input />
            </label>
            <div id="address-aria">
              <div>
                <label>주소</label>
                <input value={form?.address?.address} readOnly></input>
                <button type="button" onClick={toggleHandler}>
                  <span class="[&>svg]:h-5 [&>svg]:w-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <label>우편주소</label>
              <input value={postCode} readOnly></input>
              <label>상세주소</label>
              <input></input>
            </div>
            <div className="flex justify-between">
              <p>링크</p>
              {/* input 추가 버튼 */}
              <button className="text-white px-2 rounded" onClick={addUrlInput} type="button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 22.9091C5.97545 22.9091 1.09091 18.0251 1.09091 12C1.09091 5.97545 5.97545 1.09091 12 1.09091C18.0251 1.09091 22.9091 5.97545 22.9091 12C22.9091 18.0251 18.0251 22.9091 12 22.9091ZM12 0C5.37273 0 0 5.37273 0 12C0 18.6273 5.37273 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0ZM18 11.4545H12.5455V6.00109C12.5455 5.69836 12.3016 5.45455 12 5.45455C11.6984 5.45455 11.4545 5.69836 11.4545 6.00109V11.4545H6C5.69836 11.4545 5.45455 11.6989 5.45455 12C5.45455 12.3016 5.69836 12.5465 6 12.5465H11.4545V18C11.4545 18.3016 11.6984 18.5455 12 18.5455C12.3016 18.5455 12.5455 18.3016 12.5455 18V12.5465H18C18.3016 12.5465 18.5455 12.3016 18.5455 12C18.5455 11.6989 18.3016 11.4545 18 11.4545Z"
                    fill="#30353F"
                  />
                </svg>
              </button>
              {isOpened && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-4 rounded-lg shadow-lg">
                    <button onClick={handleClosePopup} className="text-white px-2 rounded" type="button">
                      <svg
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
                      </svg>
                    </button>
                    <DaumPostCode theme={themeObj} style={postCodeStyle} onComplete={handleAddressHandler} onClose={handleClosePopup} />
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center">
              {/* 동적으로 생성된 input 리스트 */}
              {urlList.map((input, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    value={input.value}
                    onChange={(e) => handleUrlInput(index, e)}
                    className="border border-gray-300 rounded p-2 mr-2 w-64"
                    placeholder={`Input ${index + 1}`}
                  />
                  {/* x 버튼 */}
                  <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => removeUrlInput(index)} type="button">
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="block mb-2 text-sm font-medium">
          <Editor onReady={() => {}} onChange={handleIntroduceInput} onBlur={() => {}} onFocus={() => {}} />
        </div>
        <button
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-black bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
          type="submit">
          저장
        </button>
        <button
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-black bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
          type="button">
          취소
        </button>
      </form>
    </div>
  );
};
