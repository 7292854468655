import React from 'react';

export default function Error404() {
  return (
    <div>
      Oops
      <img alt="error" src="/resource/image/404.png" className="w-[400px] h-[400px]"></img>
    </div>
  );
}
