import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const CardSlider = ({ data }) => {
  useEffect(() => {
    window.dispatchEvent(new Event('resize')); // 슬라이더 재계산
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    slidesToShow: 3, // 슬라이드에 표시할 카드 수
    slidesToScroll: 1, // 한 번에 넘어갈 카드 수
    adaptiveHeight: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // 작은 화면에서는 3개의 카드만 보여줌
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2, // 모바일 화면에서는 2개의 카드만 보여줌
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {data.map((card, index) => (
        <div key={index} className="p-2">
          <div
            className="card min-w-72 min-h-48 max-w-72 max-h-48 border-solid border-black border rounded-md m-2 bg-cover flex items-end"
            style={
              card.thumbNail.length > 0
                ? {
                    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/image?id=${card.thumbNail[0].id})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }
                : {}
            }
            onClick={() => {
              window.location.href = '/company?id=' + card.id;
            }}>
            <div className="flex flex-col text-teal-50 shadow-lg bg-black bg-opacity-60 w-full">
              <p className="font-semibold ml-2">{card.name}</p>
              <p className="ml-2">{card.job}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
