import React from 'react';

export const Footer = () => {
  return (
    <div className="h-24 mt-2">
      <p>
        @WhiteKIM의 Servispot 프로젝트입니다. <br />
        자세한 프로젝트 소개는 해당 링크를 참고해주십시오.
        <br />
        문의 및 제휴제안 : 9rudrb40@naver.com
      </p>
      <a href="https://www.flaticon.com/kr/free-icons/" title="채팅 아이콘">
        채팅 아이콘 제작자: Arkinasi - Flaticon
      </a>
      <br />
      <a href="https://www.flaticon.com/kr/free-icons/404-" title="404 오류 아이콘">
        404 오류 아이콘 제작자: alfanz - Flaticon
      </a>
      <a href="https://www.flaticon.com/kr/free-icons/-" title="홈 버튼 아이콘">
        홈 버튼 아이콘 제작자: adrianadam - Flaticon
      </a>
      <a href="https://www.flaticon.com/kr/free-icons/-" title="책 박람회 아이콘">
        책 박람회 아이콘 제작자: Circlon Tech - Flaticon
      </a>
    </div>
  );
};
